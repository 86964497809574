<template>
  <div v-if="sex.isMan || $route.params.albumType === 'private'">
    <div class="gallery-box white-box">
      <ul class="list-untyled gallery-box--links">
        <li>
          <router-link :to="{ name: 'profile' }">
            {{ user.name }}
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'albums' }">
            {{ $t('actionPages.titles.photos') }}
          </router-link>
        </li>
        <li>
          <router-link class="active" :to="{ name: 'albums' }">
            {{ $t('actionPages.utils.loadPhoto') }} ({{
              $t('actionPages.utils.namedAlbum', [$route.params.albumType])
            }})
          </router-link>
        </li>
      </ul>
      <div class="title">
        <h3 class="decor">
          {{ $t('actionPages.utils.loadPhoto') }}
          <span class="text-muted"
            >({{ $t('actionPages.utils.namedAlbum', [$route.params.albumType]) }})</span
          >
        </h3>
        <div class="gallery-box--tip">
          <form
            ref="upload-form"
            data-vv-scope="uploadForm"
            class="upload-form"
            @submit.prevent="submitUpload('uploadForm')"
          >
            <rb-button type="submit" class="start-upload btn-black" :disabled="canUpload">
              <span class="rbi rbi-upload" />
              <span>{{ $t('actionPages.utils.startUpload') }}</span>
            </rb-button>
            <div class="btn-file">
              <label for="file-upload">
                <span class="rbi rbi-add" />
                <span v-html="$t('actionPages.utils.addPhotos')" />
              </label>
              <input
                id="file-upload"
                type="file"
                accept="image/*"
                name="image"
                :data-vv-as="$t('shared.common.photos')"
                @change="uploadPhotos($event)"
              />
            </div>
          </form>
        </div>
      </div>
      <div class="action-page">
        <div class="action-page--content">
          <div v-if="files.length < 1" class="action-page--empty-warn">
            <h3 class="text-muted">
              {{ $t('actionPages.errors.noSelPhotos') }}
            </h3>
            <div class="uploader--text-default">
              <h2>{{ $t('alerts.defaultText') }}</h2>
            </div>
          </div>

          <div class="uploader">
            <div v-if="files.length" class="uploader--result set-flex">
              <div
                v-for="(item, i) in files"
                :key="item._file.size + +new Date()"
                class="uploader--result__item"
                :class="{
                  uploaded: item.uploaded,
                  uploading: item.uploading && !item.uploaded,
                  error: item.error,
                }"
              >
                <div class="item">
                  <button
                    v-if="!item.uploading && !item.uploaded"
                    class="delete-image"
                    type="button"
                    @click="delPhoto(i, item)"
                  >
                    <span class="rbi rbi-close" />
                  </button>
                  <div v-if="item.uploaded && !item.error" class="checked">
                    <span class="rbi rbi-check" />
                  </div>
                  <div v-background="{ img: item.thumb, imgClass: 'item--img' }" class="img-wrap">
                    <img class="item--img" :src="item.thumb" alt="uploaded photo" />
                  </div>
                  <div v-show="item.uploading" class="loader--wrap">
                    <preloader />
                  </div>
                </div>
                <div v-if="item.error" class="error">
                  <i class="fa fa-warning" />
                  <span>{{ item.error }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Загружаем функционал проверок, обработки файлов
 */
import { PHOTOS_UPLOAD } from '../../../mixins/utils';
import RbButton from '../../Button';
import Preloader from '../../Shared/parts/Preloader';

export default {
  components: {
    RbButton,
    Preloader,
  },
  mixins: [PHOTOS_UPLOAD],
  data() {
    return {
      files: [],
      isEmpty: false,
      photos: [],
      error: false,
      validateStartUpload: false,
      qualityStep: 0.1,
      load: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    sex() {
      return this.$store.getters.sex;
    },
    /**
     * Получить длину массива файлов
     *
     * @return {number}
     */
    filesLength() {
      return this.files.length;
    },
    /**
     * Получить длинну массива с файлами, у которых уже есть превьюшка
     *
     * @return {number}
     */
    filesWithThumbsLength() {
      return this.files.filter((i) => i.thumb).length;
    },
    /**
     * Проверяет, можно ли начинать загрузку файлов
     *
     * @return {boolean}
     */
    canUpload() {
      /*        console.log('length', this.filesLength < 1)
        console.log('val', this.validateStartUpload)
        console.log('err', this.error)
        console.log('leng', this.filesLength !== this.filesWithThumbsLength)
        console.log('load', this.load) */
      return (
        this.filesLength < 1 ||
        this.validateStartUpload ||
        this.error ||
        this.filesLength !== this.filesWithThumbsLength ||
        this.load
      );
    },
  },
  watch: {
    /**
     * В случае появления файлов начинаем их обработку
     */
    filesLength(v) {
      if (v) {
        this.files.forEach((i) => {
          if (!i.thumb) {
            this.makeThumb(i);
          }
        }, this);
      }
    },
  },
  beforeMount() {
    document.title = this.$t('actionPages.titles.uploadPhotos');
    this.preventEnter();
  },
  methods: {
    /**
     * Проверяет файлы на наличие ошибок
     */
    checkAllValid() {
      if (this.files.some(this.test)) {
        this.error = true;
      } else {
        this.error = false;
      }
    },
    test(item) {
      return item ? item.error : false;
    },
    /**
     * Запрещает вход клиенткам на страницу загрузки НЕ приватных фото с перееадресацией на 404ю стр
     */
    preventEnter() {
      if (this.sex.isWoman && this.$route.params.albumType !== 'private') {
        this.$router.push({
          name: '404',
        });
      }
    },
    /**
     * Принимает файлы с input[type="file"] и отправляет на дальнейшую обработку
     *
     * @param e {boolean}
     */
    uploadPhotos(e) {
      if (this.validateStartUpload || this.error) {
        this.files = this.files.filter((i) => !i.error);
        this.files = this.files.filter((i) => !i.uploaded);
        this.validateStartUpload = false;
      } else {
        this.files = this.files.filter((i) => !i.uploaded);
        this.files = this.files.filter((i) => !i.error);
      }

      const control = e.target;
      // When the control has changed, there are new files
      const { files } = control;

      for (let i = 0; i < files.length; i++) {
        if (files[i].type === 'image/jpeg' || files[i].type === 'image/png') {
          this.checkImage(files[i])
            .then(() => {
              const obj = {
                _file: files[i],
                uploaded: false,
                uploading: true,
                thumb: '',
                error: '',
              };
              this.files.push(obj);
              this.$refs['upload-form'].reset();
              this.checkAllValid();
            })
            .catch((e) => {
              this.$store.commit('addAlert', {
                type: 'error',
                text: e.description,
              });
              this.$refs['upload-form'].reset();
              this.checkAllValid();
            });
        } else {
          this.$store.commit('addAlert', {
            type: 'error',
            text: this.$t('alerts.incorrectImgExt'),
          });
        }
      }
    },
    /**
     * Создает превью загруженной фотографии и продолжает обработку
     *
     * @param file {object} - объект с фотографией
     */
    makeThumb(file) {
      const img = document.createElement('img');
      file.thumb = img.src = URL.createObjectURL(file._file);
      img.onload = (e) => {
        this.makeCanvas(e)
          .then((r) => {
            file.uploading = false;
            this.$set(file, 'canvas', r.canvas);
          })
          .catch((e) => {
            file.uploading = false;
            file.error = e.description;
          });
      };
    },
    /**
     * Удаляет фотографию из списка, необходимых к загрузке
     * @param index {number} - индекс объекта в массиве
     */
    delPhoto(index) {
      this.files.splice(index, 1);
      function isUploadedAll(item) {
        if (item.uploaded) {
          return true;
        }
        return false;
      }

      if (this.files.some(isUploadedAll)) {
        this.validateStartUpload = true;
      } else {
        this.validateStartUpload = false;
      }
      this.checkAllValid();
    },
    /**
     * Создает очередь на загрузку файлов
     */
    queueData() {
      const record = this.files.find((i) => !i.uploaded && !i.error);
      this.load = true;
      if (record) {
        record.uploading = true;
        this.validateStartUpload = true;

        const blob = this.reduceImage(record.canvas, this.constants.startQuality);

        this.sendBlob(blob, this.$route.params.albumType)
          .then(() => {
            record.uploaded = true;
            record.uploading = false;
            this.validateStartUpload = false;
            this.load = false;
            this.queueData();
          })
          .catch((e) => {
            record.uploading = false;
            record.error = e.description;
            this.validateStartUpload = true;
            this.load = false;
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.someErr'),
              info: 'upload photo',
            });
            /*            this.files = this.files.filter(i=>{
              return !i.error
            }) */
            this.queueData();
          });
      } else {
        this.load = false;
      }
      this.checkAllValid();
    },
    /**
     * Начинает загрузку файлов
     */
    submitUpload() {
      const nonUploaded = this.files.filter((i) => !i.uploaded);
      if (nonUploaded.length) {
        this.files = nonUploaded;
      }

      function isUploadedAll(item) {
        if (item.uploaded) {
          return true;
        }
        return false;
      }

      if (this.files.every(isUploadedAll)) {
        this.files = [];
      }
      this.queueData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/_variables.scss';
@import '../../../assets/scss/_mixins.scss';
@import '../../../assets/scss/vars';

@import '../../../assets/scss/_mixins/font-size-fluid';

$min_width: 300px;
$max_width: 1700px;
$min_font: 14px;
$max_font: 16px;

.img-wrap {
  position: relative;
  padding-bottom: 88%;
  img {
    position: absolute;
    width: 100%;
    height: 100% !important;
    left: 0;
    top: 0;
  }
}

.start-upload {
  align-items: center;
  font-weight: 400;
  font-size: 0.8571em !important;
  line-height: 1.35em;
  margin: 10px 1px;
  border: none;
  padding: 11px 15px !important;
  cursor: pointer;
  color: #fff;
  width: auto;
  height: auto;

  .rbi {
    margin-right: 10px;
    font-size: 12px;
  }
}

.error {
  color: $red;
}

.loader {
  margin: 0;
  width: 60px !important;
  height: 60px !important;

  &--wrap {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(#000, 0.4);
    padding: 0;
  }
}

.uploader {
  margin-top: 30px;

  &--text-default {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 30px;
    max-width: 650px;
    width: 100%;
    border: 1px dashed $dark;
    margin-top: 20px;
    h2 {
      color: $dark;
      @include fluid-type($min_width, $max_width, $min_font, $max_font);
    }
  }

  &--result {
    margin: 0 -10px;
    flex-wrap: wrap;
    &__item {
      position: relative;
      margin-bottom: 20px;
      flex: 0 0 20%;
      padding: 0 10px;
      .item {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          display: block;
          width: 15%;
          height: 3px;
          background: $red;
          left: 0;
          bottom: -3px;
          z-index: 1;
          transition: 0.3s;
        }
      }
      &.error {
        .delete-image {
          border-color: $red;
          color: $red;
        }
        .error {
          margin-top: 5px;
        }
      }

      img {
        width: 100%;
        object-fit: cover;
      }

      .delete-image {
        position: absolute;
        right: -10px;
        border-radius: 50%;
        top: -10px;
        padding: 0;
        height: 24px;
        background: white;
        width: 24px;
        border: 1px solid #8d8d8d;
        color: #8d8d8d;
        box-shadow: 0 0 0 4px #fff;
        z-index: 10;
        cursor: pointer;

        &:hover {
          border: 1px solid $red;
          color: $red;
        }

        span {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        svg {
          fill: currentColor;
          height: 16px;
          width: 16px;
        }
      }

      .checked {
        position: absolute;
        right: -10px;
        border-radius: 50%;
        top: -10px;
        display: flex;
        padding: 0;
        align-items: center;
        justify-content: center;
        height: 24px;
        background: white;
        width: 24px;
        border: 1px solid $green;
        box-shadow: 0 0 0 4px #fff;
        font-size: 12px;
        color: $green;
        z-index: 3;
      }
    }
  }
}

.action-page {
  &--empty-warn {
    display: flex;
    justify-content: flex-start;
    margin: 50px 0;
    height: 80vh;
    flex-direction: column;
    align-items: center;
  }
}
</style>
