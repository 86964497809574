<template>
  <div>
    <div class="white-box gallery-box">
      <ul class="list-untyled gallery-box--links">
        <li>
          <router-link :to="{name: 'profile', params: {id: user.user_id}}">
            {{ user.name }}
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'albums'}">
            {{ $t( 'actionPages.titles.photos' ) }}
          </router-link>
        </li>
        <li>
          <router-link
            class="active"
            :to="{name: 'album-purchased'}"
          >
            {{ $t( 'actionPages.titles.purchasedList' ) }}
          </router-link>
        </li>
      </ul>

      <div class="title">
        <h3 class="decor">
          {{ $t( 'actionPages.titles.purchasedList' ) }}
        </h3>
      </div>

      <div class="albums">
        <div
          v-for="user in displayedUsers"
          :key="user.user_id"
          class="album-box albums__item"
        >
          <router-link
            exact
            :to="{name: 'album-user', params: {albumType: 'purchased', id: user.user_id}}"
          >
            <div
              class="album-inner objFit"
              :style="{background: 'url('+ user.avatar_medium +') 50% 0 no-repeat'}"
            />
          </router-link>
          <div class="album-detail">
            <div class="album-name">
              <span>{{ user.name }}, {{ getUserAge(user.birthday) }}, ID: {{ user.user_id }}</span><span class="text-muted"> ({{ user.count_photos_purchased }})</span>
            </div>
          </div>
        </div>
      </div>

      <!--			<ul class="list-unstyled users">
				<router-link tag="li" :to="{name: 'album-user', params: {albumType: 'purchased', id: i.user_id}}" class="users__item" v-for="i in displayedUsers" :key="i.user_id">
					<a>
						<div v-background="{img: i.avatar_medium, imgClass: 'obj-fit'}">
							<img :src="i.avatar_medium" :alt="i.name" class="users__ava obj-fit">
						</div>
						<p class="users__info">
							{{i.name}}, {{getUserAge(i.birthday)}}, <span class="rsp-jump">ID: {{i.user_id}}<span class="text-muted"> ({{i.count_photos_purchased}})</span></span>
						</p>
					</a>
				</router-link>
			</ul>-->
      <div
        v-show="displayedUsers.length < users.length && !showPreloader"
        class="button-wrap"
      >
        <lazy-load
          :counter="defaultLimit"
          @click.native="loadMore"
        />
      </div>

      <div
        v-if="!users.length && !showPreloader"
        class="alert alert-info text-center"
      >
        <p>{{ $tc( 'actionPages.errors.noPhotos', 0 ) }}</p>
      </div>
      <div
        v-if="showPreloader"
        class="loader--wrap"
      >
        <div class="loader" />
      </div>
    </div>
  </div>
</template>

<script>
import { AGE } from '../../../mixins/utils';
import LazyLoad from '../parts/LoadMore.vue';

export default {
  components: {
    LazyLoad,
  },
  mixins: [AGE],
  data() {
    return {
      defaultLimit: 25,
      limit: 25,
      showPreloader: true,
      users: [],
    };
  },
  computed: {
    /**
			 * Получить список отображаемых пользователей
			 *
			 * @return {array}
			 */
    displayedUsers() {
      return this.users.slice(0, this.limit);
    },
    user() {
      return this.$store.getters.user;
    },
    sex() {
      return this.$store.getters.sex;
    },
  },
  created() {
    this.loadData();
  },
  mounted() {
    document.title = this.$t('actionPages.titles.purchasedList');
  },
  methods: {
    /**
			 * Загрузить список пользователей, фотографии которых приобретал клиент
			 */
    loadData() {
      this.$http.post('v1/photo/purchased-users', {
        access_token: window.localStorage['access-token'],
      }).then((response) => {
        if (response.body.status) {
          this.users = response.body.result;
        } else {
          this.$store.commit('addAlert', {
            type: 'error',
            text: this.$t('alerts.requestError'),
            info: 'load users',
          });
        }
        this.showPreloader = false;
      }, (error) => {
        this.$store.commit('addAlert', {
          type: 'error',
          text: this.$t('alerts.requestError'),
          info: error,
        });
      });
    },
    /**
			 * для кнопки "загрузить больше"
			 */
    loadMore() {
      this.limit += this.defaultLimit;
    },
  },
};
</script>

<style lang="scss" scoped>
	@import "../../../assets/scss/_variables.scss";
	@import "../../../assets/scss/mixins.scss";

  .loader--wrap{
    display: flex;
    align-items: center;
    justify-content: center;
  }

	.responsive-header {
		color: #ffffff;
		margin-bottom: 15px;
		font-size: 18px;
	}

	svg {
		fill: currentColor;
		height: 16px;
		width: 16px;
	}

	.users {
		@include customGrid(100%, 5, 18%, users__item);
		&__item {
			margin-top: 15px;
			box-shadow: 0 0 3px rgba(#000, .2);
			cursor: pointer;
			> a {
				display: block;
				color: inherit;

				&:hover,
				&:focus {
					text-decoration: none;
				}
			}
		}
		&__info {
			width: 100%;
			overflow: hidden;
			padding: 5px 5px 5px 15px;
			background: #fff;
			font-weight: bold;

			.text-muted {
				font-weight: normal;
			}
		}

		&__ava {
			width: 100%;
		}
	}

</style>
