<template>
  <div>
    <div class="white-box gallery-box">
      <div class="page-title">
        <h3 class="main-title">
          {{ $t( 'actionPages.utils.albums' ) }}
        </h3>
      </div>
      <div class="albums">
        <div
          v-for="photo in albums"
          :key="photo.album_id"
          class="album-box albums__item"
        >
          <router-link
            exact
            :to="link(photo)"
          >
            <div
              class="album-inner objFit"
              :style="{background: 'url('+ photo.preview_album +') 50% 0 no-repeat'}"
            />
          </router-link>
          <div class="album-detail">
            <div class="album-name">
              <span>{{ photo.name }}</span>
              <span>({{ countersText( photo ) }})</span>
            </div>
            <div
              v-if="sex.isMan"
              class="album-add"
            >
              <router-link
                v-if="!photo.purchased"
                exact
                :to="{name: 'albums-upload', params: {albumType : photo.name.toLowerCase()}}"
              >
                <span class="rbi rbi-add" />
                <span v-html="$t( 'actionPages.utils.addPhotos' )" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="title">
        <h3 class="decor">
          {{ $t( 'actionPages.utils.allPhotos' ) }}
        </h3>
        <button
          class="reverseAlbums hidden-xs"
          @click="reversePhotos"
        >
          <span class="rbi rbi-sort" />
          <!--          <span class="sort"><span class="asc">ascending</span>/<span class="desc">descending</span></span>-->
        </button>
      </div>
      <div>
        <div class="warning-comment">
          Using photos containing nudity is prohibited on our website. <br> Failure to comply may result in loss of membership
        </div>
        <transition-group
          v-if="photos.length"
          name="flip-list"
          tag="div"
          class="albums all-albums"
        >
          <div
            v-for="(photo, i) in displayedPhotos"
            :key="photo.photo_id"
            class="item album-box"
            @click="takePhotos(true, photos, i)"
          >
            <div class="img-wrap">
              <div
                :style="{background: 'url('+ photo.img_preview +') 50% 0 no-repeat'}"
                class="obj-fit"
              />
            </div>
          </div>
        </transition-group>
        <div
          v-show="displayedPhotos.length < photos.length && !showPreloader"
          class="button-wrap"
        >
          <lazy-load
            :counter="defaultLimit"
            @click.native="loadMore"
          />
        </div>
      </div>
      <div
        v-if="!photos && !showPreloader"
        class="alert alert-info"
      >
        <p>{{ $tc( 'actionPages.errors.noPhotos', 2 ) }}</p>
      </div>
      <div
        v-if="showPreloader"
        class="loader--wrap"
      >
        <div class="loader" />
      </div>
    </div>
  </div>
</template>

<script>
import { PHOTO_SRC, GALLERY_INDEX } from '../../../mixins/utils';
import LazyLoad from '../parts/LoadMore.vue';

export default {
  components: {
    LazyLoad,
  },
  mixins: [PHOTO_SRC, GALLERY_INDEX],
  data() {
    return {
      limit: 25,
      defaultLimit: 25,
      albums: [],
      photos: [],
      showPreloader: true,
      reversed: false,
    };
  },
  computed: {
    gallery() {
      return this.$store.getters.gallery;
    },
    /**
       * Получить список отображаемых фотографий
       *
       * @return {array}
       */
    displayedPhotos() {
      return this.photos.slice(0, this.limit);
    },
    counters() {
      return this.$store.getters.counters;
    },
    user() {
      return this.$store.getters.user;
    },
    sex() {
      return this.$store.getters.sex;
    },
    updatedGallery() {
      return this.$store.getters.updatedGallery;
    },
  },
  watch: {
    /**
       * Изменить порядок расположения альбомов при получении пола
       */
    sex() {
      // this.reverseAlbums();
    },
    /**
       * Следим за обновлением фотографии в галереи. В частности, за её поворотом
       * При обновлении фотографии - обновляем список фотографий
       */
    updatedGallery() {
      this.loadPhotos(true);
    },
  },
  created() {
    this.loadAlbums();
    this.loadPhotos();
  },
  mounted() {
    document.title = this.$t('actionPages.titles.photos');
  },
  methods: {
    /**
       * Получить ссылку для <router-link>
       *
       * @param album {object}
       * @return {object}
       */
    link(album) {
      if (album.purchased) {
        return { name: 'album-purchased' };
      }
      return { name: 'album', params: { albumType: album.name.toLowerCase() } };
    },
    /**
       * Получить счётчики с общим кол-вом фотографий в альбоме
       *
       * @param album
       * @return {number | string}
       */
    countersText(album) {
      switch (album.name) {
        case 'Private':
          return this.counters.photos_private;
        case 'Public':
          return this.counters.photos_public;
        case 'Purchased':
          return this.counters.photos_purchased;
      }
    },
    /**
       * Отобразить порядок альбомов в обратном порядке (клиенту только)
       */
    reverseAlbums() {
      if (!this.reversed && (this.sex.isMan || window.localStorage.sex !== 'woman')) {
        this.albums.reverse();
        this.reversed = true;
      }
    },
    /**
       * Загружает список фотографий пользователя
       *
       * @param updateGallery {boolean} - для обновления массива фотографий галереи в случае, если она открыта
       */
    loadPhotos(updateGallery) {
      this.$http.post('v1/photo/my-photo', {
        access_token: window.localStorage['access-token'],
        //					limit: 0,
        //					offset: 0
      }).then((response) => {
        if (response.body.status) {
          response.body.result.forEach((i) => {
            i.disableActions = false;
          });
          this.photos = response.body.result;
          if (updateGallery && this.gallery.open) {
            this.takePhotos(true, this.photos, this.gallery.index);
          }
        } else {
          this.$store.commit('addAlert', {
            type: 'error',
            text: this.$t('alerts.requestError'),
            info: 'load photos',
          });
        }
        this.showPreloader = false;
      }, (error) => {
        this.$store.commit('addAlert', {
          type: 'error',
          text: this.$t('alerts.requestError'),
          info: error,
        });
      });
    },
    /**
       * Загрузить список альбомов пользователя
       */
    loadAlbums() {
      this.$http.post('v1/photo/my-albums', {
        access_token: window.localStorage['access-token'],
      }).then((response) => {
        if (response.body.status) {
          this.albums = response.body.result;
          // this.reverseAlbums()
        } else {
          this.$store.commit('addAlert', {
            type: 'error',
            text: this.$t('alerts.requestError'),
            info: 'load albums',
          });
        }
      }, (error) => {
        this.$store.commit('addAlert', {
          type: 'error',
          text: this.$t('alerts.requestError'),
          info: error,
        });
      });
    },
    /**
       * для кнопки "загрузить больше"
       */
    loadMore() {
      this.limit += this.defaultLimit;
    },
    /**
       * Обращает порядок фотографий на противоположный
       */
    reversePhotos() {
      this.photos.reverse();
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('updateGallery', {
      open: false,
      index: 0,
      hideWatermark: false,
      photos: [],
    });
    next();
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vars";
  .loader--wrap{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .responsive-header {
    color: #ffffff;
    margin-bottom: 15px;
    font-size: 18px;
  }

  @media only screen and (max-width: 768px) {
    .gallery-box {
      padding: 20px 10px;
    }

    .title {
      justify-content: center;
    }
  }

  @media only screen and (max-width: 480px) {
    .gallery-box {
      .albums {
        flex-wrap: wrap;

        &__item {
          flex: 0 0 calc(50% - 4px) !important;
          margin-bottom: 10px;
        }
      }
    }
  }

</style>
