<template>
  <div>
    <!--<p class="visible-xs responsive-header" v-if="!targetID">{{$t( 'actionPages.utils.allPhotos' )}}</p>-->
    <!--<p class="visible-xs responsive-header" v-else>{{targetUser.name}} ID: {{targetUser.user_id}}</p>-->
    <div class="white-box gallery-box">
      <ul class="list-untyled gallery-box--links">
        <li>
          <router-link :to="{ name: 'profile', params: { id: user.user_id } }">
            {{ user.name }}
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'albums' }">
            {{ $t('actionPages.titles.photos') }}
          </router-link>
        </li>
        <li v-if="!targetID">
          <router-link
            class="active"
            :to="{ name: 'album', params: { albumType: $route.params.albumType } }"
          >
            {{ albumName }}
          </router-link>
        </li>
        <li v-else>
          <router-link
            :to="{ name: 'album-purchased', params: { albumType: $route.params.albumType } }"
          >
            {{ albumName }}
          </router-link>
        </li>
        <li v-if="targetUser.user_id">
          <router-link class="active" :to="{ name: 'profile', params: { id: targetUser.user_id } }">
            {{ targetUser.name }}
          </router-link>
        </li>
      </ul>
      <div class="title">
        <h3 v-if="!targetID" class="decor">
          {{ $t('actionPages.utils.allPhotos') }} in "{{ albumName }}" album
        </h3>
        <h3 v-else v-show="targetUser.user_id" class="decor">
          {{ targetUser.name }},
          <template v-if="!targetUser.birthday !== null">
            {{ getUserAge(targetUser.birthday) }} </template
          >, ID: {{ targetUser.user_id }}
        </h3>
        <div
          v-if="
            (sex.isMan || $route.params.albumType === 'private') &&
            $route.params.albumType !== 'purchased'
          "
          class="set-flex set-flex-y-center upload-link--wrap"
        >
          <router-link
            tag="a"
            class="upload-link"
            :to="{ name: 'albums-upload', params: { albumType: $route.params.albumType } }"
          >
            <span class="rbi rbi-add" />
            <span v-html="$t('actionPages.utils.addPhotos')" />
          </router-link>
          <button class="reverseAlbums hidden-xs" @click="reversePhotos">
            <span class="rbi rbi-sort" />
          </button>
        </div>
      </div>
      <transition-group v-if="photos.length" name="flip-list" tag="div" class="albums all-albums">
        <div
          v-for="(photo, i) in displayedPhotos"
          :key="photo.photo_id"
          class="item album-box guest-gallery item"
          @click="takePhotos(true, photos, i, hideGalleryWatermark)"
        >
          <div :class="{ dummy: photo.moderation !== 'accept' }" class="img-wrap">
            <div
              :style="{ background: 'url(' + photo.img_preview + ') 50% 0 no-repeat' }"
              class="obj-fit"
            />
            <div v-if="photo.moderation !== 'accept'" class="dummy__container">
              <div class="dummy__icon" :class="{ reject: photo.moderation === 'reject' }">
                <template v-if="photo.moderation === 'reject'">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                  >
                    <path
                      d="M23.898 4.1C21.254 1.457 17.738 0 14 0c-3.74 0-7.255 1.456-9.9 4.1C1.457 6.746 0 10.26 0 14s1.456 7.255 4.1 9.898C6.747 26.544 10.26 28 14 28c3.738 0 7.255-1.456 9.898-4.102C26.544 21.255 28 17.738 28 14s-1.456-7.255-4.102-9.9zM3 14c0-2.938 1.144-5.7 3.222-7.778C8.3 4.145 11.062 3 14 3c2.432 0 4.734.794 6.64 2.24l-15.4 15.4C3.795 18.735 3 16.43 3 14zm18.777 7.78C19.7 23.856 16.937 25 14 25c-2.432 0-4.735-.793-6.638-2.24L22.758 7.364c1.447 1.902 2.24 4.207 2.24 6.638.002 2.937-1.143 5.7-3.22 7.777z"
                    />
                  </svg>
                </template>
                <template v-if="photo.moderation === 'on moderation'">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="612"
                    height="612"
                    viewBox="0 0 612 612"
                  >
                    <path
                      d="M3.18 201.694l38.31 29.933L5.433 300.7c-18.028 31.85 12.943 62.878 32.676 73.68 19.425 10.63 49.576 11.895 77.745 11.512l50.704-82.89 38.31 20.723-66.48-123.18-135.21 1.15zM8.813 370.78l82.253 151.962c16.526 21.105 47.7 26.094 80 25.327h86.76V404.165l-164.506-1.15c-25.54 1.532-58.967-3.84-84.507-32.236zM526.31 90.732l-44.753 18.995-42.933-65.022c-19.116-31.21-61.27-19.164-80.282-7.14-18.716 11.835-34.44 37.593-47.76 62.416l47.913 84.533-36.697 23.46 139.966 1.576L526.31 90.73zM375.69 13.686l-172.786-1.732c-26.47 4.22-45.948 29.067-60.936 57.69l-42.062 75.883 125.86 69.766L306.53 71.97c11.04-23.083 31.944-49.714 69.162-58.284zm7.516 586.36l5.082-48.35 77.685-6.026c36.563-1.59 46.465-44.297 45.167-66.755-1.277-22.108-16.184-48.348-31.437-72.033l-97.162.925-2.73-43.47L310.58 485.99l72.628 114.056zm140.407-94.38l85.283-150.28c9.142-25.2-3.146-54.28-20.915-81.266l-45.976-73.577L419.97 276.8l86.2 140.12c14.836 20.845 27.993 52.04 17.445 88.747z"
                    />
                  </svg>
                </template>
              </div>
            </div>
          </div>
        </div>
      </transition-group>
      <div v-show="displayedPhotos.length < photos.length && !showPreloader" class="button-wrap">
        <lazy-load :counter="defaultLimit" @click.native="loadMore" />
      </div>

      <div v-if="!photos.length && !showPreloader" class="alert alert-info text-center">
        <p>{{ $tc('actionPages.errors.noPhotos', 0) }}</p>
      </div>
      <div v-if="showPreloader" class="loader--wrap">
        <div class="loader" />
      </div>
    </div>
  </div>
</template>

<script>
import { GALLERY_INDEX, AGE } from '../../../mixins/utils';
import LazyLoad from '../parts/LoadMore.vue';

export default {
  components: {
    LazyLoad,
  },
  mixins: [GALLERY_INDEX, AGE],
  props: {
    /**
     * ID пользователя, купленные фотографии которого просматриваются
     */
    targetID: Number,
  },
  data() {
    return {
      defaultLimit: 25,
      limit: 25,
      showPreloader: true,
      photos: [],
      targetUser: {
        name: '',
        birthday: null,
        user_id: '',
      },
    };
  },
  computed: {
    /**
     *	Получить заголовки и текстовые названия альбомов
     *
     * @return {string}
     */
    albumName() {
      switch (this.$route.params.albumType) {
        case 'public':
          return 'Public';
        case 'private':
          return 'Private';
        case 'purchased':
          return 'Saved collections';
        default:
          return 'public';
      }
    },
    /**
     * Получить состояние галереи
     *
     * @return {object}
     */
    gallery() {
      return this.$store.getters.gallery;
    },
    /**
     * Получить список отображаемых фотографий
     *
     * @return {array}
     */
    displayedPhotos() {
      return this.photos.slice(0, this.limit);
    },
    /**
     * Вычисляем, нужно ли прятать водяной знак
     *
     * @return {boolean}
     */
    hideGalleryWatermark() {
      return this.$route.params.albumType === 'private';
    },
    /**
     * Получить свой профиль
     *
     * @return {object}
     */
    user() {
      return this.$store.getters.user;
    },
    /**
     * Получить свой пол
     *
     * @return {object}
     */
    sex() {
      return this.$store.getters.sex;
    },
    /**
     * Получить флаг об обновлении состояния галереи
     *
     * @return {boolean}
     */
    updatedGallery() {
      return this.$store.getters.updatedGallery;
    },
  },
  watch: {
    /**
     * Следим за обновлением фотографии в галереи. В частности, за её поворотом
     * При обновлении фотографии - обновляем список фотографий
     */
    updatedGallery() {
      this.loadPhotos(true);
    },
  },
  created() {
    this.loadPhotos();
    if (this.targetID) this.askProfile();
  },
  mounted() {
    document.title = this.$t('actionPages.titles.gallery');
  },
  methods: {
    /**
     * Загружает список фотографий пользователя
     *
     * @param updateGallery {boolean} - для обновления массива фотографий галереи в случае, если она открыта
     */
    loadPhotos(updateGallery) {
      this.$http
        .post('v1/photo/my-album-photo', {
          access_token: window.localStorage['access-token'],
          album_type: this.$route.params.albumType,
          target_user_id: this.targetID,
          sort: 'DESC',
          with_moderation: this.$route.params.albumType === 'private' ? 'all' : 'accept',
          //					limit: 0, // gallery requires all photos
          //					offset: 0 // gallery requires all photos
        })
        .then(
          (response) => {
            if (response.body.status) {
              this.photos = this.formatPhotos(response.body.result);
              if (updateGallery && this.gallery.open) {
                this.takePhotos(true, this.photos, this.gallery.index, this.hideGalleryWatermark);
              }
            } else {
              this.$store.commit('addAlert', {
                type: 'error',
                text: this.$t('alerts.requestError'),
                info: 'load photos',
              });
            }
            this.showPreloader = false;
          },
          (error) => {
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.requestError'),
              info: error,
            });
          }
        );
    },
    /**
     * для кнопки "загрузить больше"
     */
    loadMore() {
      this.limit += this.defaultLimit;
    },
    /**
     * Трансформирует получаемый массив до нужного формата для показа галереи и списка фотографий
     *
     * @param photos {array}
     * @return требуемый формат массива
     */
    formatPhotos(photos) {
      return photos.map(
        (item) => ({
          photo_id: item.photo_id,
          img_origin: item.img_origin,
          avatar: false,
          disableActions: item.moderation && item.moderation !== 'accept',
          img_preview: item.img_preview,
          moderation: item.moderation,
          album_type: this.$route.params.albumType,
        }),
        this
      );
    },
    /**
     * Обращает порядок фотографий на противоположный
     */
    reversePhotos() {
      this.photos.reverse();
    },
    /**
     * Спрашивает профиль пользователя для отображения кратких его данных в хлебных
     * крошках и заголовке
     */
    askProfile() {
      this.$http
        .post('v1/profile/profile', {
          access_token: window.localStorage['access-token'],
          target_user_id: this.targetID,
        })
        .then(
          (r) => {
            if (r.body.status) {
              Object.assign(this.targetUser, r.body.result);
            } else {
              this.$store.commit('addAlert', {
                type: 'error',
                text: this.$t('alerts.requestError'),
                info: 'load user',
              });
            }
          },
          (err) => {
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.requestError'),
              info: `load users ${err}`,
            });
          }
        );
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('updateGallery', {
      open: false,
      index: 0,
      hideWatermark: false,
      photos: [],
    });
    next();
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/_variables.scss';

.reverseAlbums {
  margin-left: 15px;
}

.loader--wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dummy {
  position: relative;

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(#000, 0.5);
  }

  &__icon {
    color: #ffffff;
    &.reject {
      padding: 5px;
      border-radius: 50%;
      background-color: rgba(#fff, 0.8);
      color: #ef422b;
      line-height: 0;
    }
  }

  svg {
    height: 32px;
    width: 32px;
    fill: currentColor;
  }
}

.responsive-header {
  color: #ffffff;
  margin-bottom: 15px;
  font-size: 18px;
}

svg {
  fill: currentColor;
  height: 16px;
  width: 16px;
}

.upload-link {
  display: flex;
  align-items: center;
  color: $brand-primary;

  span {
    text-decoration: underline;
    margin-left: 5px;
  }
}
</style>
